<template>
	<div class="contain">
		<navbar title="聊天消息" :border="true"></navbar>
		<div class="chat-list">
			<van-swipe-cell v-for="(item,index) in list" :key="item.conversationID">
				<div class="chat-item" @click="toConversation(item.chat_id,item.nickname)">
          <div class="item-left">
            <van-badge v-if="item.unread_count > 0" :content="item.unread_count" :max="99" class="badge">
              <img :src="item.avatar?item.avatar:require('@/assets/images/chat/avatar.png')"/>
            </van-badge>
            <img v-else :src="item.avatar?item.avatar:require('@/assets/images/chat/avatar.png')"/>
          </div>
					<div class="item-right">
						<div class="content-left">
              <div class="chat-left-item">
                <div class="chat-name">{{item.nickname}}</div>
							  <div class="chat-time">{{item.last_check_time|offTime}}</div>
              </div>
							<div class="chat-content">
								{{item.last_message|msgTip}}
							</div>
						</div>
					</div>
				</div>
				<template #right>
					<div class="del-item" @click="delConversation(item.chat_id)">删除</div>
				</template>
			</van-swipe-cell>
		</div>
	</div>
</template>

<script>
	import {
		TIM
	} from '@/lib/tim/tim.js'
	import Navbar from "@/components/navbar.vue";
  import { mapGetters } from 'vuex'
  import {chatList, chatDel} from '@/api/im'

	export default {
		name:'chatList',
		components: {
			Navbar
		},
		data() {
			return {
				type: 'error',
				options: [{
					text: '删除',
					style: {
						backgroundColor: '#FE4A5D',
						color: '#FFFFFF',
						fontSize: '26px',
						width: '130px'
					}
				}],
				userSig: '',
				ReadyFlag: false,
        chatTitle: 'C2C',
				list: [],
        lastGetListTime: 0,
        listArr: []
			}
		},
    computed: {
      ...mapGetters({
        getTimReady: 'chat/getTimReady'
      })
    },
		filters: {
			offTime: function(value) {
        if (value) value = value.replace(/\-/g, '/');
				var v = value
				var date = new Date(v);
        var today = new Date();
        var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var M = date.getMonth() + 1 + '月';
        var D = date.getDate() + '日';
        var Y = date.getFullYear() + "年";
        if ((date.getDate() === today.getDate()) && (date.getMonth() === today.getMonth()) && (date.getFullYear() === today.getFullYear())) {
          return h + m;
        }
        if ((date.getDate() === today.getDate() - 1) && (date.getMonth() === today.getMonth()) && (date.getFullYear() === today.getFullYear())) {
          return '昨天';
        }
        if (date.getFullYear() === today.getFullYear()) {
          return M + D;
        }
        return Y + M + D;
			},
			msgTip:function(value){
				let msg=value;
        if(!msg) return ''
        let msg_content = JSON.parse(value)
        if (msg_content[0].MsgType == 'TIMTextElem') {
          msg = msg_content[0].MsgContent.Text
        } else if (msg_content[0].MsgType == 'TIMImageElem' || msg_content[0].MsgType == 'TIMCustomElem') {
          msg = '[图片]'
        } else {
          msg = ''
        }
				
				return msg
			}
		},
		mounted() {
			this.load();
		},
    beforeDestroy() {
      let that = this;
      this.$tim.off(TIM.EVENT.SDK_READY, that.readyFun);
			this.$tim.off(TIM.EVENT.SDK_NOT_READY,that.notReadyFun)
			this.$tim.off(TIM.EVENT.KICKED_OUT,that.kickedOutFun)
    },
		methods: {
			load() {
				let that = this;
				if (this.getTimReady) {
					that.getConversationList();
				} else {
					this.$tim.on(TIM.EVENT.SDK_READY, that.readyFun)
				}
				// this.$tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED,that.listUpdatedFun)
				this.$tim.on(TIM.EVENT.SDK_NOT_READY,that.notReadyFun)
				this.$tim.on(TIM.EVENT.KICKED_OUT,that.kickedOutFun)
			},
			readyFun(event){
				let that = this;
				that.getConversationList();
        that.setTimReady(true)
			},
			listUpdatedFun(event){
        let that = this
				if(this.getTimReady){
          let currTime = new Date().getTime();
          if (currTime - this.lastGetListTime >= 1000) {
            this.lastGetListTime = currTime
            setTimeout(() => {
              that.getConversationList();
            }, 1000)
          }
				}
			},
			delConversation(chat_id){
				if(chat_id){
          chatDel({chat_id: chat_id})
          .then(res => {
            this.$tim.deleteConversation(this.chatTitle + chat_id).then(res=>{
              this.$toast('删除会话成功')
            }).catch(err=>{
              this.$toast('删除失败')
            })
          })
				}
			},
			notReadyFun(){
        this.setTimReady(false)
				this.load();
			},
			kickedOutFun(){
        this.$toast('被踢下线,请重新登录')
        this.setTimReady(false)
			},
			getConversationList() {
        chatList()
          .then(res => {
            this.list = res.data;
          })
			},
			toConversation(cid, cname) {
        this.$router.push(`/chat/detail?cid=${cid}&&cname=${cname}`)
			},
      setTimReady(v) {
        this.$store.commit('chat/setTimReady', v);
      },
      setTime: function(value) {
				var date = new Date(value * 1000); 
        var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var M = date.getMonth() + 1 + '-';
        var D = date.getDate() + '-';
        var Y = date.getFullYear() + "-";
        return Y + M + D + h + m;
			},
		}
	}
</script>

<style lang="scss" scoped>
  .contain{
    display: flex;
    background: #fff;
    flex-direction: column;
  }
  .chat-list{
    flex: 1;
  }
	.chat-item {
		height: 160px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.item-left {
		padding: 30px;
    height: 160px;
    box-sizing: border-box;
		img {
			width: 100px;
			height: 100px;
			border-radius: 100px;
		}
    .badge{
			width: 100px;
			height: 100px;
      .van-badge--fixed{
        top: 5px;
        right: 5px;
      }
    }
	}

	.item-right {
		flex: 1;
    height: 160px;
		padding: 30px 0;
		border-bottom: 1px solid #E8E8E8;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
    box-sizing: border-box;

    .chat-name {
      font-size: 36px;
      color: #4F4F4F;
      font-weight: 500;
      padding-bottom: 5px;
      @include ellipsis(400px);
    }

    .chat-content {
      margin-top: 5px;
      font-size: 28px;
      color: #999999;
      @include ellipsis(550px);
    }
    .chat-left-item{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chat-time {
        font-size: 22px;
        color: #999999;
      }
    }
	}
  .del-item{
    width: 120px;
    height: 160px;
    background: #FF6E6E;
    color: #fff;
    text-align: center;
    line-height: 160px;
    font-size: 30px;
    font-weight: 500;
  }
</style>